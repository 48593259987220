@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.commentCard {
  background-color: unset;
  border-radius: 7px;
  transition: all 1.8s;

  &.targetResource {
    background-color: rgb(230, 208, 167) !important;
  }

  .commentAvatar {
    height: 3em;
    width: 3em;
  }

  &.comment-reply {
    background-color: unset;
    gap: 0.4em;
    transition: all 1.5s;

    &.targetResource {
      background-color: rgb(230, 208, 168) !important;
    }
  }

  &.merchantMessage {
    background-color: $lightened-background;
    border-radius: 0.4em;
    padding: 0.4em;
  }

  .commentHolder {
    display: grid;
    gap: 1em;

    &.commentReply {
      gap: 0.8em;
    }
  }

  .commentHeader {
    align-items: center;
    display: grid;
    font-size: 0.85em;
    font-weight: 600;
    gap: 0.2em;
    grid-auto-flow: column;

    .left {
      align-items: center;
      display: grid;
      gap: 1em;
      grid-template-columns: max-content auto;
    }

    .commentIdentity {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      &.externalMerchant {
        cursor: pointer;

        &:hover {
          span {
            color: $light-hashtag;
          }
        }
      }

      .username {
        color: $accent-text-color;
        font-size: 0.94em;
      }
    }

    .commentTime {
      color: $text-color;
      font-size: 0.7em;
      font-weight: normal;
      min-width: 5em;
      opacity: 0.7;
    }
  }

  .commentBody {
    font-size: 0.85em;
    font-weight: normal;
  }

  .commentFooter {
    align-items: center;
    color: #a1a0ae;
    display: grid;
    font-size: 0.7em;
    font-weight: 700;
    gap: 1.8em;
    grid-template-columns: repeat(4, max-content);

    &.commentReplies {
      font-size: 0.75em;
    }

    .likeCount,
    .replyCount,
    .iconButton {
      align-items: center;
      cursor: pointer;
      display: grid;
      gap: 0.4em;
      grid-template-columns: repeat(2, max-content);

      .actionIcon {
        align-items: center;
        display: grid;
        gap: 0.4em;
        grid-template-columns: repeat(2, max-content);

        span:first-child {
          font-size: 1.4em;
        }
      }
    }

    .replyCount {
      color: $dark-hashtag;
      cursor: pointer;
      font-size: 1.05em;
      text-transform: capitalize;
    }
  }
}

.commentSeparator {
  background-color: $border-color;
  border: unset;
  height: 1px;
  margin: 1em auto;
  width: 94%;
}

.commentRepliesWrapper {
  display: grid;
  gap: 0.7em;
  margin-left: 2em;
  margin-top: 0.7em;

  .commentReplyField {
    .MuiOutlinedInput-root {
      background-color: $primary-light;
      border-radius: 0.625em;
    }
  }

  .repliesLoader {
    padding-left: 40px;

    .loadMore {
      cursor: pointer;
      font-size: 0.9em;
      font-weight: 600;
    }

    .loadMore:hover {
      text-decoration: underline;
    }
  }
}
