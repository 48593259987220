.wrapper {
  display: grid;
  gap: 1.3em;
  min-width: 50vw;
  padding: 1.3em;

  .cropperTitle {
    align-items: center;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(3, max-content);
    justify-content: center;

    .switchImage {
      align-items: center;
      cursor: pointer;
      display: grid;
    }
  }

  .cropContainer {
    background: #333;
    height: 400px;
    position: relative;
    width: 100%;
  }

  .sliderControls {
    align-items: center;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, 1fr);

    .label {
      font-size: 0.85em;
    }
  }

  .captionContainer {
    label {
      font-size: 0.9em;
      font-weight: 500;
    }
  }

  .buttonControl {
    align-items: center;
    display: grid;
    grid-auto-flow: column;

    .controls {
      .remove {
        align-items: center;
        cursor: pointer;
        display: grid;
        font-size: 0.9em;
        font-weight: 600;
        gap: 0.4em;
        grid-template-columns: repeat(2, max-content);
        width: max-content;
      }
    }

    .buttons {
      align-items: center;
      display: grid;
      gap: 1.5em;
      grid-template-columns: repeat(2, max-content);
      justify-self: end;

      .previous {
        cursor: pointer;
        font-size: 0.95em;
        font-weight: 600;
      }
    }
  }

  .sliderContainer {
    align-items: center;
    display: grid;
    gap: 1em;
    grid-template-columns: max-content auto;
  }

  .cropControls {
    display: grid;
    gap: 0.4em;
    grid-template-columns: repeat(2, max-content);
  }
}
