@import '@ictlife/merchant/styles/variables';

.linkWrapper {
  position: relative;

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;

    &:hover {
      @apply text-primary;
    }
  }
}

.linkPreview {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 1em;
  align-items: center;
  box-shadow: $box-shadow;
  padding: 1em;
  border-radius: 0.6em;
  cursor: pointer;
  text-decoration: none;
  color: $text-color !important;
  font-size: 0.9em;

  .images {
    img {
      border-radius: 0.6em;
      max-width: 10em;
      max-height: 15em;
    }
  }

  .details {
    display: grid;
    gap: 1em;

    .description {
      font-size: 0.9em;
    }

    .site {
      display: grid;
      grid-template-columns: max-content auto;
      align-items: center;
      gap: 0.5em;

      .logo {
        display: grid;
        align-items: center;

        img {
          height: 1.5em;
          width: 1.5em;
        }
      }


      .url {
        font-size: 0.8em;
        font-weight: 600;
      }
    }
  }
}