.option {
  align-items: center;
  display: grid;
  grid-template-columns: max-content auto max-content;
  width: 100%;

  .icon {
    cursor: pointer;
  }
}

.addOption {
  align-items: center;
  display: grid;
  grid-template-columns: max-content auto max-content;

  .icon {
    cursor: pointer;
  }
}

.addNewAttribute {
  align-items: center;
  border-bottom: 1px dotted;
  cursor: pointer;
  display: grid;
  font-size: 0.8em;
  font-weight: 600;
  gap: 0.6em;
  grid-template-columns: repeat(2, max-content);
  margin-top: 0.5em;
  width: max-content;

  span:first-child {
    font-size: 1.2em;
  }
}