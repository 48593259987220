@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.thumbsWrapper {
  align-items: center;
  display: grid;
  position: relative;

  .rightScrollControl,
  .leftScrollControl {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    justify-items: center;
    position: absolute;
    z-index: 1;

    .icon {
      background-color: $primary-light;
      border-radius: 50%;
      color: $primary-color;
    }
  }

  .rightScrollControl {
    right: 0.3em;
  }

  .leftScrollControl {
    left: 0.3em;
  }
}

.thumbsContainer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.thumbHolder {
  flex-shrink: 0;
  margin-right: 1em;

  &.coverPhotoHolder {
    height: auto;
    width: 16em;

    @include phone {
      width: 13em;
    }
  }

  &.postPhotoHolder {
    height: 16em;
    width: 12em;

    @include phone {
      height: 10em;
      width: 7.5em;
    }
  }

  &.offeringPhotoHolder {
    height: 14em;
    width: 14em;

    @include phone {
      height: 8em;
      width: 8em;
    }
  }

  &.aboutBusinessHolder {
    height: 12em;
    width: 16em;

    @include phone {
      height: 9em;
      width: 12em;
    }
  }

  .imageLinearProgress {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.thumb {
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0 #c2c0c080;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: auto;

  &.active {
    border: 3px solid $primary-color;
  }

  &:hover {
    opacity: 1;
  }

  .thumbInner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-width: 0;
    overflow: hidden;
    width: 100%;

    .thumbImage {
      border-radius: 7px;
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .removeImage {
    display: none;
    z-index: 200;
  }

  &:hover,
  &.active {
    .removeImage {
      bottom: 0;
      display: flex;
      justify-content: space-around;
      justify-self: flex-end;
      left: 0;
      position: absolute;
      width: 100%;

      div {
        align-items: center;
        background-color: $primary-light;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-bottom: 3px;
        width: 30px;
      }
    }
  }
}

.thumbOverlay {
  position: absolute;
  z-index: 1;

  .overlayInfo {
    background-color: $primary-light;
    border-radius: 1em;
    font-size: 0.85em;
    font-weight: 700;
    padding: 0.4em;
  }
}

.thumbLoading {
  position: relative;

  .thumbOverlay {
    cursor: text;
  }
}

.thumb-error {
  position: relative;

  .thumbOverlay {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-style: italic;
    height: 100%;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
  }

  .overlayInfo {
    background-color: $error-color;
    color: $primary-light;
  }
}

.asset-controls {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(2, max-content);
  margin: 0.5em 0;
}

.caption {
  align-items: center;
  padding: 0.1em 0.2em;
  font-weight: 500;
  width: 14em;
  background-color: #f2f2f2;
  border-radius: 0.5em;
  margin-top: 0.4em;
  display: grid;
  grid-template-columns: auto max-content;

  .captionText {
    font-size: 0.7em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .noCaption {
      font-style: italic;
      font-weight: 400;
    }
  }

  .icon {
    display: grid;
    align-items: center;

    span {
      font-size: 0.85em;
    }
  }

  @include phone {
    width: 8em;
  }
}