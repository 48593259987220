@import '../../styles/variables';

.avatarHolder {
  align-items: center;
  background-color: $lightened-background;
  border: 1px solid $border-color;
  border-radius: 50%;
  display: grid;
  font-weight: 800;
  height: 100%;
  justify-items: center;
  overflow: hidden;
  width: 100%;
}
