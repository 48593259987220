@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables';
@import './mediaQuery';
@import 'react-phone-input-2/lib/style.css';
@import 'react-phone-number-input/style.css';
@import './carousel-holder.scss';

@mixin font($font-family, $font-file, $font-weight) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.woff2') format('woff2');
    font-weight: $font-weight;
  }
}

@include font('Quicksand', '/fonts/quicksand/light/quicksand-300', 300);
@include font('Quicksand', '/fonts/quicksand/regular/quicksand-400', 400);
@include font('Quicksand', '/fonts/quicksand/medium/quicksand-500', 500);
@include font('Quicksand', '/fonts/quicksand/semibold/quicksand-600', 600);
@include font('Quicksand', '/fonts/quicksand/bold/quicksand-700', 700);

:root {
  --app-background: #fff;
  --app-text: #000000cb;
  --text-alt-color: #000000cb;
  --lightened-background: #f6f6f6;
  --login-wrapper-background: #f6f6f681;
  --box-shadow-color: #00000029;
  --text-header: #000000d1;
  --border-color: #eeebeb;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.app-wrapper {
  background-color: $primary-light !important;
  color: var(--app-text);
  height: 100%;
  transition: background-color 1s;

  .page-loader {
    position: absolute;
    width: 100%;
  }
}

html,
body,
#__next {
  height: 100%;
  overflow: hidden;

  @include laptop1366 {
    font-size: 14px;
  }

  @include laptop {
    font-size: 16px;
  }

  @include laptop1650min {
    font-size: 20.8px;
  }
}

body {
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  margin: 0;
}

.success-section {
  background-color: $success-color;
  border-radius: 3px;
  color: $primary-light;
  padding: 10px;

  &.password-reset {
    margin-top: 1em;
  }
}

.button,
.next-button {
  align-items: center;
  margin-top: 10px;
  position: relative;

  &.dense-width {
    max-width: max-content;
  }

  .button-loader {
    left: 50%;
    margin: -12px 0 0 -12px;
    position: absolute;
    top: 50%;
  }
}

.image-count {
  color: $primary-color;
}

.empty-post {
  align-items: center;
  background-color: $primary-light;
  border-radius: 0.625em;
  box-shadow: $box-shadow;
  display: grid;
  gap: 1em;
  height: max-content;
  justify-items: center;
  padding: 1em;
  text-align: center;
  width: 100%;

  .icon {
    height: 12em;
  }

  .first-post {
    font-size: 1.3em;
  }

  .desc {
    font-size: 0.9em;
  }

  span {
    font-size: 0.8em;
    font-weight: 500;
  }
}

.profile-avatar {
  background-color: $primary-color !important;
  color: $primary-light !important;
  font-size: 0.8em !important;
  font-weight: 700 !important;

  &.settings {
    height: 20em;
    width: 20em;
  }
}

.topbar-avatar {
  cursor: pointer;
  font-size: 0.8em !important;
  font-weight: 700 !important;
  height: 3.3em;
  width: 3.3em;
}

.comment-avatar {
  .profile-avatar {
    font-size: 0.8em;
    height: 2.2em;
    width: 2.2em;
  }
}

.custom-button {
  align-items: center;
  background-color: $primary-color;
  border: 0;
  border: 1px solid $primary-color;
  border-radius: 0.15em;
  box-sizing: border-box;
  color: $theme-light;
  cursor: pointer;
  display: grid;
  font-family: 'Quicksand', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  height: max-content;
  line-height: 1.75;
  min-width: 64px;
  outline: 0;
  padding: 0.3em 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: max-content;

  @include tablet {
    padding: 0.3em 3em;
  }

  &.danger {
    background-color: #f00 !important;
    border: 1px solid #f00 !important;
    color: #fff !important;
  }

  &:visited {
    color: $theme-light;
  }

  &.sm {
    font-size: 0.7rem;
    padding: 0.2em 0.6em;
  }

  &.lc {
    text-transform: capitalize;
  }

  &.bold {
    font-weight: 600;
  }

  &.rounded {
    border-radius: 7em;
  }

  &.with-icon {
    column-gap: 0.7em;
    grid-auto-flow: column;
  }

  &.nav-button {
    column-gap: 1em;
    font-size: 0.65em;
    font-weight: 700;
    padding: 0.4em 2em;
    text-transform: capitalize;
  }

  &.full-width {
    width: 100%;
  }

  &:hover {
    background-color: $button-hover-color;
    border: 1px solid $button-hover-color;
  }

  &:disabled {
    background-color: $disabled-button-color;
    border: 1px solid transparent;
    color: $disabled-button-text;
    cursor: not-allowed;

    &:hover {
      background-color: $disabled-button-color;
      border: 1px solid transparent;
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      background-color: rgba(229, 26, 35, 0.04);
    }

    &.danger {
      background-color: transparent !important;
      color: #f00 !important;
    }

    &:disabled {
      background-color: $disabled-button-color !important;
      border: 1px solid transparent !important;
      color: $disabled-button-text !important;
    }
  }

  &.white {
    border: 1px solid $theme-light !important;
    color: $theme-light !important;
  }

  &.default {
    border: 1px solid var(--box-shadow-color, #00000029);
    color: $accent-text-color;
    width: unset;
  }

  &.dark {
    border: 1px solid #000 !important;
    color: #000 !important;
  }

  .icon {
    font-size: 1.6em;
  }
}

.app-form {
  .column-input {
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1em;

    .input-group {
      margin-bottom: unset;
      width: 100%;
    }
  }

  label {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .label-group {
    align-items: center;
    display: grid;
    gap: 0.2em;
    grid-template-columns: repeat(2, max-content);

    @include phone {
      grid-template-columns: unset;
    }
  }

  input,
  select,
  textarea {
    border: 0.1em solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: $text-alt-color;
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    font-size: 0.85rem;
    font-weight: 500;
    height: 3rem;
    margin: 2px 0;
    padding: 1em;
    width: 100%;

    &:focus {
      @apply border border-primary outline-none;
    }

    &:disabled {
      background-color: #8e8e8e1f;
      cursor: not-allowed;
    }
  }

  .input-group {
    display: grid;
    gap: 0.3em;
    margin-bottom: 1em;
    text-align: left;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    .helper-text {
      font-size: 11px;
    }

    .username-suggestions {
      font-size: 0.85em;

      .suggestion {
        color: $light-hashtag;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .button-controls {
    display: grid;
    justify-items: center;
    margin-top: 3em;
  }
}

.mobile-hidden {
  @include phone {
    display: none;
  }
}

.mobile-only {
  @include lg {
    display: none;
  }
}

.profile-completion-progress {
  border-radius: 7px;
  height: 6px !important;
}

.react-tel-input {
  .form-control {
    background-color: $lightened-background;
    font-size: 0.85rem !important;
    height: 3rem !important;
    width: 100% !important;
  }

  .flag-dropdown,
  .country-list,
  .search {
    background-color: $lightened-background !important;
  }

  .selected-flag:hover,
  .flag-dropdown.open .selected-flag {
    background-color: $primary-light;
  }

  .search {
    padding: 1px;

    span {
      display: none;
    }
  }

  .country:hover,
  .highlight {
    background-color: $primary-light !important;
  }

  .search-box {
    margin-left: unset !important;
  }
}

.dialog-wrapper {
  background-color: $primary-light;
  box-shadow: $box-shadow;
  color: $content-text-color;
  min-width: 25em;

  &.theme-switcher {
    width: 30em;

    @include phone {
      width: 100%;
    }
  }

  .dialog-title,
  .dialog-body,
  .dialog-controls {
    padding: 10px 20px;

    &.theme-popper {
      background-color: $primary-light;

      .description {
        font-size: 0.8em;
      }

      .theme {
        cursor: pointer;
        display: grid;
        gap: 0.5em;
        grid-template-columns: 2em auto;
        padding: 1em 0;

        .sub-text {
          font-size: 0.8em;
        }
      }

      .action {
        display: grid;
        justify-items: center;
      }

      .message-section {
        display: grid;
        justify-items: center;
      }
    }

    .list {
      margin: 0.5em 0;

      .list-item {
        align-items: center;
        display: grid;
        gap: 1em;
        grid-template-columns: max-content auto;
      }
    }

    .slug-text {
      font-weight: 600;
      font-size: 0.9em;
      display: 'flex';
      align-items: center;
    }
  }

  .button {
    padding: 0 7px;
  }

  .dialog-title {
    background-color: var(--dialog-title-background, #f2f6f9);
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    width: 100%;

    .title {
      flex-grow: 1;
    }

    .close-button {
      cursor: pointer;
    }

    .icon {
      cursor: pointer;
      font-size: 1.2em;
    }
  }

  .dialog-controls {
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-end;

    @include phone {
      flex-direction: column;
      justify-content: unset;
    }
  }
}

.facebook-icon {
  color: #3b5998;

  &:visited {
    color: #3b5998;
  }
}

.instagram-icon {
  color: #fb3958;

  &:visited {
    color: #fb3958;
  }
}

.twitter-icon {
  color: #00acee;

  &:visited {
    color: #00acee;
  }
}

.whatsapp-icon {
  color: #075e54;

  &:visited {
    color: #075e54;
  }
}

// MUI overrides
.override {
  &.dark {
    .MuiPaper-root {
      background-color: $lightened-background;
      color: $primary-text-color;
    }
  }

  textarea {
    font-size: 0.9rem;
    font-weight: 500;
  }

  // Input fields
  .MuiOutlinedInput-root {
    background-color: var(--input-background-color, '#3d4955');

    &:hover fieldset {
      border: 0.1em solid $text-header !important;
    }

    &.Mui-focused fieldset {
      border: 0.1em solid $primary-color !important;
    }
  }

  .MuiInputBase-root {
    color: unset !important;
    font-size: 0.9em !important;
    font-weight: unset !important;

    input {
      font-weight: 500;
    }

    .MuiChip-root {
      background-color: var(--lightened-background);
      color: $tag-color;
      font-weight: 500;
    }
  }

  &.login-request {
    .MuiDialog-paperWidthSm {
      max-width: unset;
    }
  }

  .MuiDialog-paper {
    margin: unset;
  }

  .MuiCheckbox-root {
    color: $primary-text-color;
  }
}

.MuiMenu-paper {
  color: unset !important;

  .MuiMenuItem-root {
    font-size: 0.9em !important;
    font-weight: 500 !important;
  }
}

// Rating Stars
.MuiRating-iconFilled {
  color: $primary-color !important;
}

.MuiRating-iconEmpty {
  color: $disabled-button-color !important;
}

.more-options-popper {
  border-radius: 0.625em;
  z-index: 10000;
}

.carousel-root {
  height: 100%;
  overflow: hidden;
  width: 100%;

  .carousel {
    height: 100%;
    width: 100%;

    .slider-wrapper {
      height: 100%;
      width: 100%;

      .slider {
        height: 100%;
        width: 100%;
      }
    }

    .slide {
      background-color: $primary-light;
      overflow: hidden;

      .legend {
        background: $primary-light;
        font-size: 0.85em;
      }
    }

    .control-dots {
      .dot {
        background-color: #929292;
        box-shadow: $box-shadow;
        height: 6px;
        opacity: unset;
        width: 6px;

        &:focus {
          outline: none;
        }

        &.selected {
          background-color: $primary-color;
        }
      }
    }
  }
}

.country-list {
  max-height: 20rem !important;
  width: 20rem !important;
}

a {
  color: $dark-hashtag;

  &:visited {
    color: $visited-link;
  }

  &:hover {
    color: $primary-color;
  }
}

.link {
  a {
    color: unset;
  }
}

.crop-image-wrapper {
  .react-crop-element {
    img {
      margin: auto;
      max-height: 30em;
      width: auto;
    }
  }
}

.notification-icon {
  font-size: 1.6em;

  &.open-notifications {
    color: $primary-color;
  }
}

.settings-icon {
  span {
    font-size: 1.6em;
  }

  .active {
    color: $primary-color;
  }
}

.login-request {
  display: grid;
  gap: 1em;
  justify-items: center;

  .tag-line {
    display: grid;
    justify-items: center;

    .main {
      font-size: 1.3em;
      font-weight: 600;
    }

    .sub {
      font-size: 0.9em;
      font-weight: 600;
    }
  }

  .controls {
    align-items: center;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, max-content);
  }
}

hr {
  background-color: var(--hr-background-color, #03030314);
  border: unset;
  color: var(--hr-background-color, #03030314);
  height: 1px;
  margin: 0.8em auto;
  width: 100%;
}

.button-loader-holder {
  margin-top: 0;
}

.live-wrapper {
  min-height: 10em;

  .live-holder {
    display: grid;
    gap: 1em;
    justify-items: center;
    padding: 1.5em;

    @include phone {
      padding: 1em 0;
    }

    .next-step {
      text-align: center;
    }

    .share-profile {
      align-items: center;
      display: grid;
      gap: 2em;
      grid-template-columns: repeat(4, max-content);

      .copy-link {
        align-items: center;
        cursor: pointer;
        display: grid;
        font-size: 0.9em;
        gap: 0.5em;
        grid-template-columns: repeat(2, max-content);

        .link {
          color: rgb(127, 128, 127);
        }

        .check {
          color: $success-color;
        }
      }

      i {
        cursor: pointer;
        font-size: 1.8em;
      }
    }

    .illustration {
      display: grid;
      justify-items: center;
      margin-bottom: 1em;

      img {
        width: 90%;
      }
    }

    .desc {
      font-size: 0.9em;
      text-align: center;
    }

    &.start-post {
      h2 {
        font-size: 1em;
        font-weight: 500;
        text-align: center;
      }

      p {
        text-align: center;
      }
    }

    &.complete-profile {
      .list {
        margin: 1em 0;

        .item {
          align-items: center;
          cursor: pointer;
          display: grid;
          font-size: 0.9em;
          font-weight: 600;
          gap: 1em;
          grid-template-columns: auto max-content;
          padding: 0.5em 1em;

          &.complete-item {
            color: #a1a0ae;
          }

          .check-circle {
            color: $success-color;
            font-size: 1.2em;
          }

          .cancel {
            color: $error-color;
            font-size: 1.2em;
          }
        }
      }
    }

    h1 {
      font-size: 1.4em;
      text-align: center;
    }

    .control {
      display: grid;
      gap: 1em;
      grid-template-columns: repeat(2, max-content);
    }
  }
}

.exit-wrapper {
  display: grid;
  gap: 1em;
  justify-items: center;
  padding: 1em;

  .controls {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, max-content);
    justify-items: center;
  }
}

.error-section {
  align-items: center;
  color: $error-color;
  display: grid;
  width: 100%;

  &.md-width {
    width: 24em;
  }

  .retry {
    cursor: pointer;
  }
}

.MuiIconButton-label {
  input {
    cursor: inherit;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.hash-tag {
  color: $light-hashtag;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.next-section {
  right: 0;

  .icon {
    right: 0.7em;
  }
}

.next-section,
.prev-section {
  align-items: center;
  color: $primary-light;
  cursor: pointer;
  display: grid;
  font-size: 1.2em;
  height: 100%;
  position: absolute;
  top: 0;
}

.post-reply-field {
  .MuiOutlinedInput-root {
    background-color: var(--input-background-color, '#3d4955');
    border-radius: 0.625em;

    @include phone {
      border-radius: 0;
    }
  }
}

.mainContentTitle {
  font-size: 1.1em;
  font-weight: 700;
  margin: 1em 0 0.5em;
  text-transform: capitalize;

  &.mobile {
    @include tablet {
      display: none;
    }
  }
}

.gallery-holder {
  border-radius: 7px;
  overflow: hidden;
  width: 100%;

  img {
    border: 1px solid var(--secondary-text-color, #707070);
    border-radius: 7px;
    height: auto !important;
    max-height: 15em !important;
    max-width: 100%;
    min-width: 15em;
    width: auto !important;

    @include phone {
      max-width: 40% !important;
      min-width: 9em !important;
    }
  }

  .ReactGridGallery_tile-viewport {
    background-color: var(--app-background);
    border-radius: 5px;
    height: unset !important;
    overflow: hidden;
    width: unset !important;
  }

  .ReactGridGallery_tile {
    border-radius: 7px;
    margin-bottom: 0.7em !important;
    margin-right: 0.7em !important;
    overflow: hidden;
    width: min-content;
  }
}

.name-search {
  .MuiFormControl-root {
    padding: 1em;
  }
}

.add-photo-dropzone {
  align-items: center;
  border: 1px solid #ccc;
  color: $tag-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  height: 100%;
  justify-content: center;
  outline: none;
  text-align: center;
  transition: border 0.24s ease-in-out;
  width: 100%;
  @apply rounded-md lg:rounded-lg;

  &.cover-photo {
    min-height: 14em;
    min-width: 21em;
  }

  &.disabled-zone {
    border: 2px dashed #ebebe4;
  }

  span {
    padding: 0 5px;
  }
}

.rating-point {
  .MuiRating-sizeSmall {
    font-size: 0.9rem;
  }
}

.overall-rating {
  .MuiRating-sizeSmall {
    font-size: 1.1rem;
  }
}

.likes-list-wrapper {
  .likes-list-holder {
    min-width: 20em;

    .likes-list-header {
      border-bottom: 1px solid #4b658433;
      display: flex;
      font-size: 0.9em;
      font-weight: 600;
      justify-content: space-between;
      margin-bottom: 0.5em;
      padding: 0.85em 1em;

      .like {
        align-items: center;
        cursor: pointer;
        display: grid;
        gap: 0.4em;
        grid-template-columns: repeat(2, max-content);
      }
    }

    .likes-list-body {
      max-height: 30em;
      min-height: 10em;
      overflow-y: auto;
      padding: 0 1em 1em;

      .loading-list {
        display: grid;
        font-size: 0.9em;
        justify-items: center;
      }

      .user-like {
        align-items: center;
        display: grid;
        gap: 0.4em;
        grid-template-columns: max-content auto;
        margin-bottom: 0.8em;

        .display-pic {
          font-size: 0.8em;
          height: 3.1em;
          width: 3.1em;
        }

        .name {
          font-size: 0.9em;
          font-weight: 600;

          .username {
            color: $icon-color;
            font-size: 0.75em;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
}

.login-request-wrapper {
  height: 100vh;
  width: 100vw;
}

.post-tag {
  border: 1px solid $border-color;

  &.active {
    background-color: var(--lightened-background);
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  &:hover {
    background-color: var(--lightened-background);
    border: 1px solid $primary-color;
    color: $primary-color;
  }
}

.theme-text {
  color: $primary-color !important;
  opacity: 1 !important;
}

.theme-background {
  background-color: $primary-color;
}

.theme-container {
  background-color: $primary-light;
}

.preview-tag {
  &:hover {
    color: $primary-color !important;
  }
}

.review-bar {
  background-color: lighten($primary-color, 40) !important;
  border-radius: 10px;

  div {
    background-color: $primary-color;
    border-radius: 10px;
  }

  &:hover {
    background-color: lighten($primary-color, 35);

    div {
      background-color: lighten($primary-color, 6);
    }
  }
}

#nprogress {
  .bar {
    background: $primary-color;
  }

  .peg {
    box-shadow: 0 0 10px $primary-color, 0 0 5px $primary-color;
  }
}

.translate-y-full {
  --tw-translate-y: 100% !important;
}

.opacity-0 {
  opacity: 0;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;

  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.css-1hwfws3 {
  padding: 0 8px !important;
}

.phone-element {
  display: none;

  @include phone {
    display: block;
  }
}

.danger-icon {
  color: red;
}

.success-icon {
  color: $success-color;
}

.text-handle {
  color: var(--text-alt-color);
  font-weight: 700;
  text-decoration: none;

  &:visited {
    color: unset;
  }

  &:hover {
    color: $text-color;
  }
}

.no-margin {
  margin-bottom: 0 !important;
}

.offline {
  display: grid;
  justify-items: center;
  margin-top: 10%;
  gap: 1em;

  .holder {
    .icon {
      span {
        font-size: 2.4em;
      }
    }

    .title {
      font-size: 1.2em;
      font-weight: 500;
    }

    .description {
      font-size: 0.9em;
    }
  }
}

.button-link {
  text-decoration: none;
}

.override-calendar {
  .MuiCalendarPicker-root {
    width: max-content;

    div:first-child {
      padding: 0;
    }
  }
}
