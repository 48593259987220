@import '@ictlife/merchant/styles/variables';

.carouselImageWrapper {
  font-size: 0.6em;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  div {
    height: 100%;
    width: 100%;
  }

  .loader, .error {
    align-items: center;
    display: grid;
    height: 100%;
    justify-items: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .error {
    height: 100%;

    .message {
      display: grid;
      height: max-content;
    }
  }

  img {
    height: 100%;
  }
}

.imageError {
  align-items: center;
  color: #f00;
  display: grid;
  font-size: 0.8em;
  justify-items: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  .message {
    background-color: $primary-dark;
    color: $primary-light;
  }
}
