@import '../../styles/variables';

.postEditor {
  background-color: var(--input-background-color);
  position: relative;
}

.suggestionsList {
  background-color: $primary-light;
  border-radius: 7px;
  box-shadow: $box-shadow;
  max-height: 30em;
  min-width: 16em;
  overflow: auto;
  position: absolute;
  z-index: 1;

  .suggestion {
    border-bottom: 1px solid $border-color;
    cursor: pointer;
    font-weight: 600;
    padding: 14px 20px;

    &:hover {
      background-color: $lightened-background;
    }
  }

  .loader {
    padding: 2px 0 0;
    text-align: center;
  }
}
