@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.createOffering {
  padding: 1em 1em 4em;
    @include lg {
      padding: 1em 1.2em 4em;
    }
}

  .chipHolder {
    margin-top: 0.5em;
    @apply grid grid-cols-3 gap-4 lg:gap-8 lg:grid-cols-5;

    .categoryChip {
      border: 1px solid $border-color;
      border-radius: 1.2em;
      cursor: pointer;
      font-size: 0.75em;
      font-weight: 600;
      padding: 0.4em 0.8em;
      width: max-content;
      @apply hover:border-primary;


      &.active {
        @apply text-primary border border-primary;
      }
    }
  }

  .thumbsContainer {
    margin-top: 0.5em;
  }

  .categoryWrapper {
    display: grid;
    grid-template-columns: max-content auto;
    background: 'orange' !important;

    &:hover {
      .remove {
        visibility: visible !important;
      }
    }

    &.addNew {
      .field {
        margin-top: 1em;
      }

      .indicator {
        height: 50%;
      }
    }

    .indicator {
      border-left: 1.5px dotted;
      gap: 1em;
      height: 100%;
      width: 1px;
    }
  }

  .offeringField {
    margin-bottom: 1em;

    .titleHolder {
      align-items: center;
      display: grid;
      gap: 0.5em;
      grid-template-columns: 1em auto;
      margin-bottom: 0.5em;

      .categoryTitleHolder {
        align-items: center;
        cursor: pointer;
        display: grid;
        gap: 0.1em;
        grid-template-columns: repeat(2, max-content);

        .remove {
          visibility: hidden;
        }
      }

      .subcategoryField {
        align-items: center;
        display: grid;
        gap: 1em;
        grid-template-columns: auto max-content;
      }

      .add {
        align-items: center;
        border-bottom: 1px dotted;
        color: #e51a23;
        cursor: pointer;
        display: grid;
        font-size: 0.85em;
        font-weight: 600;
        gap: 0.2em;
        grid-template-columns: max-content max-content;
        width: max-content;

        &:hover {
          color: #e51a23;
        }
      }

      .horizontalIndicator {
        border-top: 1.5px dotted;
        gap: 1em;
        height: 1px;
        width: 100%;
      }
    }

    .pricePreview {
      align-items: center;
      margin-top: 1em;
      display: grid;
      gap: 0.5em;
      grid-template-columns: repeat(2, max-content);
      font-size: 0.8em;

      .value {
        color: rgb(4, 177, 4);
        font-weight: 600;
      }
    }

    label {
      font-size: 0.8em;
      font-weight: 600;
    }

    .attributesHolder {
      display: grid;
      gap: 0.4em;


      .attribute {
        align-items: center;
        display: grid;

        .attributeName {
          align-items: center;
          display: grid;
          font-size: 0.8em;
          font-weight: 600;
          gap: 0.5em;
          grid-template-columns: repeat(2, max-content);

          .attributeAge {
            background-color: #e51a23;
            border-radius: 5em;
            color: #fff;
            font-size: 0.7em;
            font-weight: 800;
            padding: 0.3em 0.8em;
          }
        }

        .attributeValue {
          input {
            background-color: #fff;
          }
        }
      }
    }

    .stars {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      gap: 1em;
      align-items: center;

      .labels {
        font-weight: 600;
        font-size: 0.85em;
      }
    }

    .MuiRating {
      font-size: 2.5em;
    }
  }

  .field {
   @apply lg:w-3/5;

    input {
      background-color: #fff;
    }
  }

  .description {
    font-size: 0.8em;
  }

  .categoriesTree {
    align-items: center;
    display: grid;
    font-size: 0.75em;
    font-weight: 600;
    grid-auto-flow: column;
    margin-bottom: 0.4em;
    width: max-content;

    .category {
      align-items: center;
      color: #e51a23;
      display: grid;
      grid-auto-flow: column;
    }
  }

  .controls {
    margin: 2em 0;
    width: max-content;

    button {
      font-weight: 600;
      padding: 1em 4em;
    }
  }

.loadingAttributes {
  padding: 0 1.5em;
}

.adorn {
  font-size: 0.85em;
  font-weight: 600;
}

.mainSectionTitle {
  @apply mt-8 text-lg font-semibold mb-4;
}

.addNewAttribute {
  align-items: center;
  border-bottom: 1px dotted;
  cursor: pointer;
  display: grid;
  font-size: 0.8em;
  font-weight: 600;
  gap: 0.6em;
  grid-template-columns: repeat(2, max-content);
  margin-top: 0.5em;
  width: max-content;

  span:first-child {
    font-size: 1.2em;
  }
}
