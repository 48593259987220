@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.topbar-wrapper {
  border-bottom: 1px solid $border-color;
  display: none;
  height: 100%;
  min-height: 5.1em;

  @include lg {
    display: grid;
  }
}

.search-wrapper {
  display: grid;
  justify-items: center;
}

.dashboard-topnav {
  display: none;
  min-height: 5.1em;

  @include lg {
    align-items: center;
    color: $accent-text-color;
    display: grid;
    gap: 1em;
    grid-template-columns: max-content auto max-content;
    height: 100%;
    margin: auto;
    padding: 0 3.5%;
    width: 100%;
    z-index: 1;

    .business-brand-aside {
      align-items: center;
      display: flex;
      grid-area: logo-area;

      .business-brand {
        align-items: center;
        cursor: pointer;
        display: flex;

        .logoImg {
          height: 45px;
          width: 110px;
          margin-right: 0.55em;
        }

        .name {
          display: grid;
          justify-items: center;

          .ictlife {
            color: $primary-color;
            font-size: 1.45em;
            font-weight: 700;
          }

          .merchant-word {
            font-size: 0.7em;
            font-weight: 700;
          }
        }
      }
    }

    .left-section {
      justify-self: start;
    }

    .mid-section {
      justify-self: center;

      .top-header {
        display: grid;
        font-size: 0.8em;
        font-weight: 600;
        gap: 0.8em;
        grid-auto-flow: column;
        margin: auto;
        width: max-content;

        .link {
          align-items: center;
          cursor: pointer;
          display: grid;
          gap: 0.3em;
          grid-template-columns: repeat(2, max-content);
          justify-content: center;
          padding: 0 1em;

          a {
            text-decoration: none;

            &:visited {
              color: unset;
            }
          }

          a:hover {
            color: $primary-color;
          }

          &.active {
            a {
              color: $primary-color;
              font-weight: 700;
            }
          }
        }
      }

      .search-field {
        transition: all 2s;
        width: 100%;
      }
    }

    .right-section {
      align-items: center;
      color: $text-header;
      display: grid;
      gap: 3em;
      grid-auto-flow: column;
      justify-self: right;

      .theme-switcher {
        font-size: 1.2em;
      }

      div {
        cursor: pointer;
      }

      .custom-button {
        font-weight: 700;
        padding: 0.4em 1.8em;

        .icon {
          font-size: 1.6em;
        }
      }
    }
  }

  &.username-route-topnav {
    .business-brand {
      img {
        height: 35px;
      }

      .name {
        font-size: 0.85em;
      }
    }
  }
}

.profile-section {
  align-items: center;
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(3, max-content);

  .profile-details {
    display: flex;
    flex-direction: column;
    font-size: 0.85em;
    font-weight: 600;
    max-width: 18em;
    text-transform: capitalize;

    @include phone {
      max-width: 7em;
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .role {
      color: $primary-color;
      font-size: 0.9em;
    }
  }

  .chevron-down {
    align-items: center;
    display: flex;
  }
}

.mobile-top-nav {
  align-items: center;
  border-bottom: 1px solid $border-color;
  display: flex;
  height: 100%;
  justify-content: space-between;
  justify-items: center;
  min-height: 3em;
  padding: 0 3%;

  @media (min-width: 1024px) {
    display: none;
  }

  .left-section {
    align-items: center;
    cursor: pointer;
    display: grid;
    gap: 0.4em;
    grid-template-columns: repeat(2, max-content);

    .username {
      font-size: 0.9em;
      font-weight: 600;
    }

    .MuiIconButton-label {
      color: $primary-text-color;
    }
  }

  .right-section {
    align-items: center;
    display: grid;
    gap: 2em;
    grid-auto-flow: column;

    .theme-switcher {
      font-size: 1.2em;
    }
  }

  .profile-avatar,
  .business-avatar {
    font-size: 0.8em;
    height: 2.5em;
    width: 2.5em;
  }
}

.topnav-popper {
  background-color: $primary-light;
  border-radius: 5px;
  box-shadow: $box-shadow;
  font-size: 0.9em;
  min-width: 25em;

  .business-setting {
    display: grid;
    gap: 1em;
    justify-items: center;
    padding: 1em;

    .main-pop-avatar {
      height: 7em;
      width: 7em;
    }

    .identity {
      color: var(--activity-text-color);
      display: grid;
      gap: 0.3em;
      text-align: center;

      .name {
        font-size: 1.1em;
        font-weight: 600;
        text-overflow: ellipsis;
      }

      .username {
        color: $accent-text-color;
        font-size: 0.9em;
        font-weight: 600;
      }
    }
  }

  .business-list {
    padding: 0.5em 1em;

    .business {
      align-items: center;
      cursor: pointer;
      display: grid;
      gap: 1em;
      grid-template-columns: 3em auto auto;

      .logo {
        align-items: start;
        display: grid;
        justify-items: center;

        .business-avatar {
          font-size: 0.95em;
          font-weight: 700;
          height: 3em;
          width: 3em;
        }
      }

      .name {
        display: grid;
        font-weight: 600;
        gap: 0.3em;
        width: 100%;

        .b-name {
          color: var(--activity-text-color);
          font-size: 0.8em;
          font-weight: 700;
          margin-top: 0.8em;
        }

        .b-username {
          color: $accent-text-color;
          font-size: 0.8em;
          margin-bottom: 0.8em;
        }
      }

      .active-business {
        color: var(--activity-text-color);

        .icon {
          font-size: 1.4em;
        }
      }
    }

    .separator {
      display: grid;
      grid-template-columns: 3em auto;

      hr {
        width: 90%;
      }
    }

    .more {
      color: $light-hashtag;
      cursor: pointer;
      font-size: 0.8em;
      font-weight: 700;
      margin-top: 1.2em;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .popup-footer {
    display: grid;
    justify-items: center;
    padding: 0.5em;

    .custom-button {
      margin-bottom: 0.8em;
    }
  }
}

.notification-popper {
  display: grid;
  grid-template-rows: max-content auto;
  width: 30em;

  @include phone {
    width: 100vw;
  }

  .notification-header {
    background-color: var(--dialog-title-background, #f2f6f9);
    color: $primary-text-color;
    display: flex;
    justify-content: space-between;
    padding: 0.8em 1em;

    .title {
      font-size: 1.1em;
    }

    .header-link {
      color: var(--text-color, #4d425d);
      font-size: 600;
    }

    .general-actions {
      align-items: center;
      display: grid;
      gap: 1em;
      grid-auto-flow: column;

      .header-link {
        cursor: pointer;
        font-size: 0.9em;

        &:hover {
          text-decoration: underline;
        }
      }

      .close {
        cursor: pointer;

        .icon {
          font-size: 1.6em;
        }
      }
    }
  }

  .notification-content {
    display: grid;
    justify-items: center;
    max-height: 80vh;
    overflow-y: auto;

    .empty {
      color: #a1a0ae;
      font-size: 0.9em;
      font-weight: 600;
      padding: 1em;
    }

    .notification {
      border-bottom: 1px solid $border-color;
      color: $accent-text-color;
      cursor: pointer;
      display: grid;
      font-size: 0.9em;
      font-weight: 600;
      gap: 0.8em;
      grid-template-columns: max-content auto max-content;
      padding: 0.7em 1em;
      position: relative;
      width: 100%;

      .notification-avatar {
        span {
          color: #f3976c;
          font-size: 1.25em;
        }
      }

      .notification-footer {
        font-size: 0.9em;
        padding: 0.5em 0;
      }

      &.unread {
        color: var(--text-color);

        .notification-body {
          .message {
            font-weight: 600;
          }
        }

        .notification-footer {
          color: #a1a0ae;
          font-weight: 600;
        }
      }

      &:hover {
        background-color: $lightened-background;
      }

      .notification-action {
        .icon {
          color: $primary-color;
          font-size: 0.8em;
        }
      }
    }

    .pagination {
      color: $primary-color;
      padding: 1em;

      .see-more-notifications {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.logout-popper {
  max-width: 25em;

  .link {
    align-items: center;
    color: var(--activity-text-color);
    cursor: pointer;
    display: grid;
    gap: 1em;
    grid-template-columns: 0.7em max-content;
    padding: 15px 20px;

    .icon {
      font-size: 1.4em;
    }
  }
}

.more-options-wrapper {
  background-color: $primary-light;
  border: 1px solid var(--box-shadow-color);
  border-radius: 0.625em;
  font-size: 0.85em;
  overflow: hidden;

  .option {
    align-items: center;
    border-bottom: 1px solid var(--box-shadow-color);
    color: var(--text-alt-color);
    cursor: pointer;
    display: grid;
    gap: 0.6em;
    grid-template-columns: max-content auto;
    padding: 15px 25px;

    .icon {
      color: $icon-color;
      font-size: 1.1em;
    }

    &:hover {
      background-color: $primary-color;
      color: $theme-light;

      i {
        color: $theme-light;
      }
    }
  }

  .option:last-child {
    border-bottom: unset;

    &:hover {
      border-radius: 0 0 7px 7px;
    }
  }

  .option:first-child:hover {
    border-radius: 7px 7px 0 0;
  }
}

.copied {
  color: $success-color;
}
