@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.postCardContainer {
  .post-overlay,
  .inner-overlay {
    display: none;
  }

  .reportedPost {
    border-radius: 5px;
    box-shadow: $box-shadow;
    display: grid;
    gap: 0.7em;
    margin-bottom: 20px;
    padding: 1em;
    text-align: center;

    .action {
      display: grid;
      height: unset !important;
      justify-items: center;
    }
  }

  .postCard {
    border-radius: 0.625em;
    column-gap: 1.7em;
    display: grid;
    margin-bottom: 20px;
    row-gap: 0.8em;

    @include laptop {
      grid-template-columns: 22.75em auto;
    }

    .postSection {
      align-items: center;
      background-color: $primary-light;
      border-radius: 1.2em;
      box-shadow: $box-shadow;
      display: grid;
      overflow: hidden;
      transition: background-color 1s;

      @include phone {
        border-radius: 1px;
      }

      .postSectionHeader {
        padding: 1em;

        @include phone {
          padding: 0.5em;
        }

        .merchantInfo {
          display: grid;
          gap: 1em;

          @include phone {
            gap: 0.5em;
          }

          .info {
            display: flex;
            justify-content: space-between;

            .businessIdentity {
              align-items: center;
              display: grid;
              font-size: 0.85em;
              font-weight: 600;
              gap: 1.8em;
              grid-template-columns: max-content auto;

              @include phone {
                gap: 1em;
              }

              .profileAvatar {
                height: 3.8em;
                width: 3.8em;

                @include phone {
                  height: 3em;
                  width: 3em;
                }
              }

              .name {
                span {
                  text-transform: capitalize;
                }

                .username {
                  color: $accent-text-color;
                  font-size: 0.9em;
                  font-weight: 500;
                }

                .owner {
                  font-size: 0.8em;
                }
              }
            }

            .moreOptions {
              align-items: center;
              display: grid;
              font-weight: 600;

              .more {
                cursor: pointer;
              }
            }
          }

          .postTitle {
            font-size: 0.85em;
            font-weight: 600;
          }
        }
      }

      .mainCaption {
        align-items: center;
        border-bottom: 1px solid #4b658433;
        border-top: 1px solid #4b658433;
        color: $primary-text-color;
        cursor: text;
        display: flex;
        font-size: 2em;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        overflow-y: auto;
        padding: 7px;
        text-align: center;
        width: 100%;

        span {
          color: $primary-text-color;
          font-size: 1.4em;
        }
      }

      & > div {
        height: 100%;
        width: 100%;
      }

      .postCarouselWrapper {
        height: calc(100vw * 4 / 3);
        width: 100vw;

        img {
          object-fit: cover !important;
        }

        @include tablet {
          height: calc(22.75em * 4 / 3);
          width: 100%;
        }
      }

      .postSectionFooter {
        .headerInfo {
          padding: 1em 1.2em;

          @include phone {
            padding: 0.5em 0.6em;
          }
        }

        .time {
          color: $accent-text-color;
          display: block;
          font-weight: 600;
        }

        .postUserInfo {
          display: none;
        }
      }
    }

    .postSection::-webkit-scrollbar {
      display: none;
    }

    .commentsSectionHolder {
      display: none;

      @include tablet {
        background-color: $primary-light;
        border: 1px solid #4a576633;
        border-bottom: unset;
        border-radius: 0.625em;
        display: flex;
        flex-direction: column;
        transition: background-color 1s;
        width: 100%;
      }
    }
  }
}

.postCommentsSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: hidden;
  position: relative;

  .postHeader {
    border-bottom: 1px solid var(--hr-background-color, #03030314);
    display: grid;
    gap: 1em;
    padding: 1em 1.6em 0;
    width: 100%;

    @include phone {
      gap: 0.5em;
      padding: 0.5em 0.7em 0;
    }

    .postAvatar {
      align-items: center;
      display: grid;
      gap: 1.2em;
      grid-template-columns: max-content auto;

      .profileAvatar {
        font-size: 0.8em;
        height: 3.2em;
        width: 3.2em;
      }

      .name {
        font-size: 0.9em;
        font-weight: 600;
      }
    }
  }

  .mobileHeader {
    display: none;

    @include phone {
      display: block;
    }
  }

  .headerSeparator {
    background-color: $border-color;
    border: unset;
    height: 1px;
    margin: 0 auto 1em;
    width: 96%;
  }

  .comments {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 10px;
    width: 100%;

    @include desktop {
      padding: 0 30px;
    }

    .note {
      align-items: center;
      color: #a1a0ae;
      display: grid;
      font-size: 0.8em;
      justify-items: center;
      padding: 1em 0;
      text-align: center;
    }
  }

  .addComment {
    width: 100%;
  }
}

.morePostsSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-style: italic;

  .commentsAction {
    color: $light-hashtag;
    cursor: pointer;
    font-style: normal;

    &:hover {
      text-decoration: underline;
    }
  }
}

.editPostWrapper {
  margin: 0 auto;
  padding: 0.8em 0;
  width: 85%;

  @include phone {
    font-size: 0.9em;
    width: 94%;
  }
}

.mobileCommentsWrapper {
  display: none;

  @include phone {
    display: block;
    height: 100%;
  }

  .mobileCommentsHolder {
    height: 100%;

    .mobileHeader {
      border-bottom: 1px solid $border-color;
      display: none;
      padding: 1em;

      @include phone {
        display: block;
      }

      .back {
        align-items: center;
        cursor: pointer;
        display: grid;
        gap: 0.5em;
        grid-auto-flow: column;
        justify-content: space-between;

        span {
          font-weight: bold;
        }

        i {
          font-size: 1.2em;
        }
      }
    }
  }
}

.headerInfo {
  display: grid;
  gap: 1em;
  grid-template-rows: repeat(3, max-content);

  .caption {
    font-size: 0.9em;
    font-weight: normal;
  }

  .time {
    display: none;
    font-size: 0.7em;
    font-weight: 600;
  }

  .postUserInfo {
    display: grid;
    grid-auto-flow: column;

    @include phone {
      display: none;
    }

    .name {
      align-items: center;
      display: grid;
      font-weight: 600;
      gap: 0.2em;
      grid-template-columns: repeat(3, max-content);
      text-transform: capitalize;
    }

    .caption {
      font-size: 0.9em;
      line-height: 1.2;
      text-align: left;
      white-space: pre-wrap;

      .handle {
        cursor: pointer;
        font-weight: 700;
      }
    }
  }

  .postInfo {
    align-items: center;
    color: #a1a0ae;
    display: grid;
    font-size: 0.85em;
    font-weight: 600;
    grid-auto-flow: column;

    div {
      flex-grow: 1;
    }

    .likeArea,
    .commentArea {
      align-items: center;
      cursor: pointer;
      display: grid;
      font-size: 0.95em;
      gap: 0.6em;
      grid-template-columns: repeat(2, max-content);

      span:first-child {
        font-size: 1.4em;
      }
    }
  }
}
