@import './variables';

.imagesHolder {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .imageCarousel {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    .noImage {
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 0.8em;
      display: grid;
      height: 100%;
      justify-items: center;

      .holder {
        align-items: center;
        color: $icon-color;
        display: grid;
        gap: 0.3em;
        justify-items: center;

        span:first-child {
          font-size: 1.6em;
        }

        span:last-child {
          font-size: 0.6em;
          font-weight: 600;
        }
      }
    }
  }
}
