@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.addPhotoContainer {
  align-items: center;
  border: 1px solid $border-color;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  margin-bottom: 2em;
  outline: none;
  padding: 1.5em 3em;

  @include phone {
    padding: 1.5em 0.5em;
  }

  @include laptop {
    width: 100%;
  }

  .more {
    display: none;

    &.preview-section {
      display: block !important;
    }
  }

  .post-info {
    display: none !important;

    &.preview-section {
      display: grid !important;
    }
  }

  .newPostHeader {
    align-items: center;
    color: $primary-color;
    display: grid;
    font-size: 1.2em;
    font-weight: 700;
    gap: 1em;
    grid-template-columns: repeat(2, max-content);
    left: 0;
    margin: 0 auto;
    text-align: left;
    top: 0;
    z-index: 1;

    .back {
      align-items: center;
      display: grid;

      .icon {
        cursor: pointer;
      }
    }
  }

  .textfieldContainer {
    .caption-field {
      textarea {
        font-size: 1em;
      }
    }
  }

  .newPostContent {
    display: grid;
    gap: 1em;
    grid-template-columns: 5fr 1px 4fr;
    width: 100%;

    @include phone {
      grid-template-columns: unset;
    }

    .separator {
      border-right: 1px solid $border-color;
      height: 100%;
      margin: 0 3em;

      @include phone {
        display: none;
      }
    }

    .postTags {
      box-shadow: unset;
      height: unset;
    }

    .header {
      display: grid;
      gap: 1em;
      padding: 2em 0;

      .title {
        font-size: 0.9em;
        font-weight: 700;
      }

      .subText {
        font-size: 0.8em;
        font-weight: 600;

        .hashTag {
          color: $primary-color;
        }
      }
    }

    .preview {
      display: grid;
      gap: 1em;
      grid-template-rows: repeat(2, max-content);
      justify-content: center;
      margin-left: 3.5em;
      width: 100%;

      @include phone {
        display: none;
      }

      h1 {
        font-size: 1em;
        margin-bottom: 1em;
        text-align: center;
      }

      .postButton {
        column-gap: unset;
      }
    }
  }

  .postButton {
    display: grid;
    gap: 2em;
    grid-auto-flow: columns;
    margin: 1.5em 0;

    .cancelButton {
      @include phone {
        display: none;
      }
    }

    .button {
      margin-right: 10px;
      margin-top: unset;
    }

    .actionable {
      display: flex;
    }
  }
}

.note {
  font-size: 0.8em;
  font-weight: 600;
}
